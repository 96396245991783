<template>
  <div class="login-container">
   <!-- 未登录的子组件 -->
   <!-- <NotLogin v-if="!user"></NotLogin> -->
   <!-- 已登录的子组件 -->
   <Login></Login>
  </div>
</template>

<script>
import NotLogin from '@/views/My/component/NotLogin'
import Login from './component/login'
import { mapState } from 'vuex'

export default {
  name: '',
  components: {
    NotLogin,
    Login
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  created () {

  },
  methods: {

  },
  computed: {
    ...mapState(['user'])
  }
}
</script>

<style lang="less" scoped>
.login-container {
    height: 100%;
}
</style>
