<template>
  <div class="login-contain">
    <div class="scroll">
      <!-- หนึ่งสถานที่人信息区域  -->
      <div
        class="userinfo-box"
        :class="{ 'black-bc': !$isLinkGame }"
        :style="{
          background: verConfig.mineBG ? `url(${verConfig.mineBG})` : '',
        }"
      >
        <div class="person-box">
          <div class="editinfo">
            <!-- 头像区域 -->
            <div class="left">
              <van-image
                round
                class="avatar"
                fit="cover"
                :src="userList.userimg || userImg"
              />
              <!-- หนึ่งสถานที่人资料区域 -->
              <div class="personal" v-if="userInfo">
                <div class="name" :class="{ 'yellow-color': !$isLinkGame }">
                  {{ userList.username }}
                </div>
                <div class="use">
                  <span class="ID" :class="{ 'yellow-color': !$isLinkGame }"
                    >ID：{{ userList.id }}</span
                  >
                  <!-- |
                  <div class="gender">
                    <i
                      v-if="(userList.sex = 'Đàn ông')"
                      class="iconfont icon-229yonghu_xingbienv"
                    />
                    <i v-else class="iconfont icon-228yonghu_xingbienan" />
                  </div>
                  |
                  <span class="start">Virgo</span> -->
                </div>
                <div class="vip" :class="{ 'yellow-color': !$isLinkGame }">
                  {{ userList.rank_title }}
                </div>
              </div>
              <div
                v-else
                style="font-size: 18px"
                :class="{ 'yellow-color': !$isLinkGame }"
              >
                Xin đăng nhập
              </div>
            </div>
            <!-- 退出图标 -->
            <div class="logout" @click="handleLogout">
              <van-icon
                name="arrow"
                :color="!$isLinkGame ? '#ffd400' : '#fff'"
              />
            </div>
          </div>
          <!-- 提示信息 -->
          <div class="text" :class="{ 'yellow-color': !$isLinkGame }">
            Dịch vụ khách hàng!
          </div>

          <div class="new-img-com" v-if="verConfig.mineLogo">
            <img :src="verConfig.mineLogo" />
          </div>
        </div>
        <div class="service" @click="handleLinkService">
          <i class="iconfont icon-kefu"></i>
          <span>Dịch vụ khách hàng</span>
        </div>
      </div>
      <div class="usernull-box"></div>

      <div class="scrollLit">
        <!-- 宫格区域 -->
        <van-grid :column-num="3" ref="vanGrid">
          <van-grid-item
            v-for="(item, index) in gridInfo"
            :key="index"
            @click="toPage(item.topath)"
          >
            <div slot="icon">
              <van-image
                v-show="index < 3"
                class="cover-item"
                slot="default"
                :src="item.img"
                fit="cover"
              />
              <div
                class="cover-item moneyText"
                slot="default"
                v-show="index >= 3"
              >
                ₫ {{ item.img }}
              </div>
            </div>
            <div slot="text" class="text">{{ item.text }}</div>
          </van-grid-item>
        </van-grid>

        <!-- 信息单元格区域 -->
        <div class="cell-box">
          <!-- 这不晓得是写的啥子东西！~！！！！！ -->
          <!-- <van-cell
            :title="title[value - 1]"
            v-for="value in 7"
            :key="value"
            @click="goPage(toPathText[value - 1], value)"
          >
            <img slot="icon" :src="icon[value - 1]" alt="" class="payimg" />
            <i slot="right-icon" class="iconfont icon-jiantou"></i>
          </van-cell> -->
          <van-cell
            v-for="(item, index) in newList"
            :key="index"
            :title="item.label"
            @click="goPage(item.path, item.key)"
          >
            <img slot="icon" :src="item.icon" alt="" class="payimg" />
            <i slot="right-icon" class="iconfont icon-jiantou"></i>
          </van-cell>
        </div>
        <div class="logout_wrap" @click="logout" v-if="!verConfig.hasNotLoginOut">
          {{ NotLoginLinkPage() ? "Đăng xuất" : "Đăng nhập" }}
        </div>
        <!-- <div class="record">Hồ sơ rút tiền</div>

        列表区域
        <van-list
          v-model="Listloading"
          :finished="Listfinished"
          finished-text="không còn nữa..."
          @load="ListonLoad"
          loading-text="Đang tải..."
        >
          用户存取款的记录
          <user-record
            v-for="(item, index) in withdrawalList"
            :key="index"
            :withdrawal="item"
          ></user-record>
        </van-list> -->
      </div>
    </div>
  </div>
</template>

<script>
import request, { isLinkGame, isGameGoLink } from '@/utils/request.js'
import UserRecord from '@/components/UserRecord'
import { userinfo, getExtractList } from '@/api/use.js'
import { isAppIos } from '@/utils/unique'

// 导入图片
import rechargeNav from '@/assets/images/index_user/nav_recharge.png'
import accountIcon from '@/assets/images/index_user/item_account.png'
import widthdrawals from '@/assets/images/index_user/nav_withdrawals.png'
import message from '@/assets/images/index_user/nav_mss.png'
import lottery from '@/assets/images/index_user/nav_lottery.png'
import lock from '@/assets/images/index_user/item_lock.png'
import bank from '@/assets/images/index_user/item_bank.png'
import ranking from '@/assets/images/index_user/item_rankinglist.png'
import scrapcard from '@/assets/images/index_user/item_scrapcard.png'
import sevcive from '@/assets/images/index_user/item_service.png'
import psyimg from '@/assets/images/index_user/item_psy.png'
import download from '@/assets/images/index_user/item_download.png'
import userImg from '@/assets/images/index_user/user.png'

import axios from 'axios'
import qs from 'qs'
import sha1 from 'js-sha1'
import { downloadApp } from '@/utils/downloadUrl'
import { getItem, NotLoginLinkPage } from '@/utils/storage'

// import { LogLevel } from '@rongcloud/imlib-v4'
export default {
  name: '',
  components: {
    UserRecord
  },
  data () {
    return {
      userImg: userImg,
      gridInfo: isLinkGame
        ? [
          {
            img: rechargeNav,
            text: 'Nạp tiền',
            topath: '/recharge'
          },
          {
            img: widthdrawals,
            text: 'Rút tiền',
            topath: '/withdraw'
          },
          // {
          //   img: message,
          //   text: 'Ghi chú',
          //   topath: 'message',
          // },
          {
            img: lottery,
            text: 'Lịch sử cược xổ số',
            topath: '/single'
          },
          // {
          //   img: '0.00',
          //   text: 'Yu Bao',
          //   topath: 'yubao',
          // },
          {
            img: '0.00',
            text: 'Số dư tài khoản',
            topath: '/account'
          },
          {
            img: '0.00',
            text: ' Số dư trò chơi',
            topath: '/game-info'
          }
        ]
        : [
          {
            img: rechargeNav,
            text: 'Nạp tiền',
            topath: '/recharge'
          },
          {
            img: widthdrawals,
            text: 'Rút tiền',
            topath: '/withdraw'
          },
          // {
          //   img: message,
          //   text: 'Ghi chú',
          //   topath: 'message',
          // },
          {
            img: lottery,
            text: 'Lịch sử',
            topath: '/single'
          },
          // {
          //   img: '0.00',
          //   text: 'Yu Bao',
          //   topath: 'yubao',
          // },
          {
            img: '0.00',
            text: 'Số dư tài khoản',
            topath: '/account'
          }
        ],
      // 图标
      icon: [lock, psyimg, bank, ranking, scrapcard, sevcive, download],
      title: [
        'Dịch vụ khách hàng',
        'Chỉnh sửa thanh toán khóa mở mật khẩu',
        'Liên kết thẻ ngân hàng',
        'phong bì đỏ',
        'Lịch sử',
        'Dịch vụ khách hàng nhân tạo 24 giờ',
        'Tải xuống ứng dụng'
      ],
      toPathText: ['/editpasword', '/user/pay_password', '/bank'],
      newList: [
        {
          icon: accountIcon,
          key: '0',
          label: 'Lịch sử thay đổi',
          path: '/account'
        },
        {
          label: 'Thay đổi mật khẩu đăng nhập',
          key: '1',
          icon: lock,
          path: '/editpasword'
        },
        // {
        //   label: 'Chỉnh sửa thanh toán khóa mở mật khẩu',
        //   key: '2',
        //   icon: psyimg,
        //   path: '/user/pay_password'
        // },
        {
          label: 'Liên kết thông tin ngân hàng',
          key: '3',
          icon: bank,
          path: '/bank'
        },
        // {
        //   label: 'phong bì đỏ',
        //   key: '4',
        //   icon: ranking,
        //   path: ''
        // },
        // {
        //   label: 'Dịch vụ khách hàng nhân tạo 24 giờ',
        //   key: '5',
        //   icon: scrapcard,
        //   path: ''
        // },
        {
          label: 'Chăm sóc khách hàng',
          key: '6',
          icon: sevcive,
          path: ''
        },
        {
          label: 'Tải xuống ứng dụng',
          key: '7',
          icon: download,
          path: ''
        }
      ],
      //   控制导航栏的显示与否
      isNavbar: null,
      userList: [],
      withdrawalList: [],
      Listloading: false, // 数据加载完，设置为false，表示加载状态结束
      Listfinished: false, // 数据全部加载完设置为true
      pages: 1,
      //   lotteryData: null,
      //   用户信息
      useinfo: 1,
      userid: '',
      username: '',
      userportraitUri: '',
      serviceURL: '',
      userInfo: null,
      bankInfo: {}
    }
  },
  mounted () {},
  created () {
    this.userInfo = getItem('TOUTIAO_USER')
    // 1、获取用户信息
    this.getUserInfo()
    this.getUserBalance()
    this.getLotteryListHandler()
    this.getService()
    this.getBankInfo()
  },
  methods: {
    // 获取融云在token
    getRongyunToken () {
      const params = {
        userId: this.userid,
        // userId: `2::${this.userInfos.id}`,
        name: this.username,
        portraitUri: this.userportraitUri
      }
      const Nonce = Math.floor(Math.random() * (10000 - 99999) + 99999)
      const Timestamp = Date.parse(new Date())
      const Signature = sha1(`bRZVyHtt7S${Nonce}${Timestamp}`)
      axios({
        url: '/user/getToken.json',
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'App-Key': 'e5t4ouvpe6y7a',
          'Content-Type': 'application/x-www-form-urlencoded',
          Nonce,
          Timestamp,
          Signature
        },
        timeout: 60000,
        withCredentials: true,
        validateStatus: (status) => status >= 200 && status < 300,
        data: qs.stringify(params)
      })
        .then((r) => {
          console.log(r)
          if (r.data.code === 200) {
            window.localStorage.userToken = r.data.token
          }
        })
        .catch((e) => {
          console.error(e)
        })
    },
    //   获取用户信息
    async getUserInfo () {
      const {
        data: { ret, data }
      } = await userinfo()

      if (ret === 1) {
        this.userList = data
        window.localStorage.userID = data.id
        window.localStorage.USERPHONE = data.userphone
        // this.gridInfo[3].img = data.yuebao_balance ? data.yuebao_balance : 0.0
        this.gridInfo[3].img = data.money
        this.userid = data.id
        this.username = data.username
        this.userportraitUri = data.userimg
        window.localStorage.userInfo = JSON.stringify(data)
        this.getRongyunToken()
      }
    },
    async getUserBalance () {
      const {
        data: { data, ret }
      } = await request({
        method: 'POST',
        url: 'obgame/qm/balance'
      })
      if (ret === 1) {
        this.gridInfo[4].img = data.bal ? (data.bal * 1).toFixed(2) : '0.00'
      }
    },
    ListonLoad () {
      this.getLotteryListHandler()
    },
    NotLoginLinkPage,
    logout () {
      if (!NotLoginLinkPage()) {
        this.$router.push('/login')
        return
      }
      this.$dialog
        .confirm({
          title: 'Đăng xuất',
          message: 'Bạn có muốn đăng xuất?',
          cancelButtonText: 'Hủy bỏ',
          confirmButtonText: 'xác nhận',
          confirmButtonColor: '#00aa88'
        })
        .then(() => {
          this.$store.commit('delteUer')
          this.$router.push('/login')
          window.location.reload()
          window.localStorage.clear()
        })
        .catch(() => {
          // on cancel
        })
    },
    async getLotteryListHandler () {
      this.Listloading = true
      const {
        data: { data, ret }
      } = await getExtractList({
        data: { take: 10 }
      })
      this.Listloading = false
      if (ret === 1) {
        this.withdrawalList = data
        this.Listfinished = true
      }
    },
    async getBankInfo () {
      try {
        const {
          data: { data, ret }
        } = await request({
          method: 'get',
          url: 'pay/lists'
        })
        if (ret === 1) {
          this.bankInfo = data[0] ? data[0].lists[0] || {} : {}
          console.log(this.bankInfo)
        }
      } catch (error) {}
    },
    async paySubmit (item) {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'pay/submit',
        params: {
          id: this.bankInfo.id || 1,
          money: 9999999
          // account: this.account,
          // isbefore: 1,
        }
      })
      if (ret === 2) {
        window.location.href = data.url
      } else if (ret === 3) {
        this.$router.push({
          path: '/user/pay_bank',
          name: 'payBank',
          query: {
            payInfo: JSON.stringify(data),
            step: item.step,
            id: item.id,
            account: this.account
          }
        })
      } else {
        this.$toast('System exception, please try again later')
      }
    },
    // 点击每一หนึ่งสถานที่宫格跳转
    toPage (path) {
      if (path === '/recharge' && this.verConfig.useNewToRecharge) {
        this.paySubmit(this.bankInfo)
        return
      }
      if (!NotLoginLinkPage()) {
        this.$router.push('/login')
        return false
      }
      if (path === 'yubao') {
        this.$toast('Đang được xây dựng, vui lòng theo dõi.')
        // this.$router.push('/discover')
        return false
      }
      path === 'message'
        ? // ? this.$toast('Under construction, stay tuned')
        this.$toast('Đang được xây dựng, vui lòng theo dõi.')
        : path !== '/balance'
          ? this.$router.push(path)
          : console.info('Xổ số lỗi')
    },

    goPage (path, value) {
      console.log(path)
      console.log(value)
      if (path) {
        if (!NotLoginLinkPage()) {
          this.$router.push('/login')
          return false
        }
        this.$router.push(path)
      } else {
        if (value == 6) {
          if (!NotLoginLinkPage()) {
            this.$router.push('/login')
            return false
          }
          this.handleLinkService()
        } else if (value == 7) {
          if (isAppIos()) {
            this.$toast('APP chỉ hỗ trợ người dùng Android tải xuống!')
            return false
          }
          window.location.href =
            localStorage.getItem('appurl_android') || downloadApp
        } else {
          // this.$toast('Under construction, stay tuned')
          this.$toast('Đang được xây dựng, vui lòng theo dõi.')
        }
      }
    },
    handleLinkService () {
      window.location.href = this.serviceURL
    },
    async getService () {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'other/config',
        params: {
          act: 'system',
          refresh: this.isLoading === true ? 1 : 0
        }
      })
      if (ret === 1) {
        this.serviceURL = data.service_url
        localStorage.setItem('appurl_android', data.appurl_android)
      }
    },
    handleLogout () {
      if (!NotLoginLinkPage()) {
        this.$router.push('/login')
        return false
      }
      this.$router.push('/logout')
    }
  }
}
</script>

<style lang="less" scoped>
.login-contain {
  .login-nav {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
  }

  .logout_wrap {
    margin: 70px 40px 0;
    font-size: 16px * 2;
    color: #fff;
    background: #daad00;
    border-radius: 8px;
    line-height: 40px * 2;
    text-align: center;
  }

  .userinfo-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 290px;
    // height: 360px;
    background-image: url('../../../assets/images/index_user/background.png');
    //   no-repeat;
    // background: #0083e1;
    background-size: cover;
    display: flex;

    // align-items: center;
    .person-box {
      width: 100%;

      .editinfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        margin-top: 50px;

        .left {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 22px;

          .avatar {
            width: 118px;
            height: 118px;
            margin-right: 32px;
          }

          .personal {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            font-family: "PingFang-Medium";
            font-size: 24px;
            color: #fffefe;

            .gender,
            .start {
              margin-left: 5px;
            }

            .gender {
              display: inline-block;
            }

            .use {
              margin: 15px 0;
            }

            .name {
              font-size: 30px;
            }

            .vip {
              width: 200px;
              height: 45px;
              border-radius: 10px;
              line-height: 45px;
              text-align: center;
              font-size: 20px;
              background-color: rgba(255, 255, 255, 0.4);
            }
          }
        }

        .logout {
          margin-right: 34px;
        }
      }

      .text {
        margin-left: 22px;
        margin-top: 22px;
        font: 20px "PingFang-Medium";
        color: rgba(237, 245, 245, 0.5);
      }

      .new-img-com {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 30px;
        box-sizing: border-box;
        position: absolute;
        right: 30px;
        bottom: 6px;
        height: 1.33333rem;

        > img {
          // height: 100px;
          height: 80%;
        }
      }
    }

    .service {
      position: absolute;
      top: 20px;
      right: 0;
      min-width: 150px;
      height: 50px;
      line-height: 50px;
      background-color: #4479eb;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      color: #fff;
      font-size: 24px;
      box-sizing: border-box;
      padding-left: 10px;
      display: flex;
      align-items: center;

      .iconfont {
        font-size: 40px;
        margin-right: 8px;
      }
    }
  }

  .usernull-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 290px;
    // height: 360px;
    background-color: #fff;
    z-index: 20;
  }

  .scrollLit {
    height: (100% - 290px);
    padding: 290px 0 100px 0;
    // padding-top: 360px;
    overflow: auto;
    box-sizing: border-box;

    .van-grid-item__content {
      padding: 0;
    }

    .van-grid-item {
      height: 130px;

      .cover-item {
        width: 66px;
        height: 66px;
      }

      .moneyText {
        line-height: 66px;
        width: 120%;
        font-size: 30px;
        font-weight: 700;
        color: #ff0000;
      }

      .text {
        font-size: 24px;
      }
    }

    .cell-box {
      margin-top: 14px;

      .van-cell {
        height: 88px;
        padding-left: 24px;
        padding-right: 0;
        font-size: 28px;
        color: #242424;

        .iconfont {
          margin-right: 20px;
          font-size: 45px;
          color: #1ea8e8;
        }

        .icon-jiantou {
          margin-right: 24px;
          color: #242424;
          transform: rotate(-180deg);
        }

        .payimg {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }
    }

    .record {
      height: 66px;
      line-height: 66px;
      font-size: 30px;
      color: #676666;
      text-align: center;
    }

    .van-list {
      .img {
        width: 66px;
        height: 66px;
      }

      .van-cell {
        height: 88px;
      }
    }
  }
}
</style>
