<template>
  <div class="user-record">
    <van-cell>
      <!-- 利用作用域插槽来实现 -->
      <!-- 用户头像 -->
      <div slot="icon">
        <van-image
          class="record-item"
          slot="default"
          :src="withdrawal.userimg"
          fit="cover"
          round
        />
      </div>
      <!-- 用户 -->
      <div slot="title">
        <span>Số đuôi{{ withdrawal.phone_l4 }}</span>
        <span class="drawal">Số tiền rút</span>
        <span class="money">{{ withdrawal.money }}Nhân dân tệ</span>
      </div>
      <!-- 时间 -->
      <!-- <div slot="default">1分钟</div> -->
    </van-cell>
  </div>
</template>

<script>
export default {
  // TODO
  props: {
    withdrawal: {
      type: Object,
      required: true,
    },
  },

  name: '',
  components: {},
  data() {
    return {}
  },
  mounted() {},
  created() {},
  methods: {},
}
</script>

<style lang="less" scoped>
.user-record {
  .van-cell {
    height: 88px;
    padding: 11px 24px;
    border-bottom: 1px solid #eff3f4;
    .record-item {
      width: 66px;
      height: 66px;
      margin-right: 20px;
    }
    .drawal {
      margin: 0 8px;
    }
    .money {
      color: #ff0000;
    }
  }
}
</style>
